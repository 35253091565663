import type { GridProps, HTMLChakraProps } from '@chakra-ui/react';
import {
  Box,
  Grid,
  Flex,
  Text,
  Link,
  VStack,
  Skeleton,
  useColorModeValue,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import React from 'react';

import type { CustomLinksGroup } from 'types/footerLinks';

import config from 'configs/app';
import type { ResourceError } from 'lib/api/resources';
import useApiQuery from 'lib/api/useApiQuery';
import useFetch from 'lib/hooks/useFetch';
import useIssueUrl from 'lib/hooks/useIssueUrl';
import { copy } from 'lib/html-entities';
import IconSvg from 'ui/shared/IconSvg';
import { CONTENT_MAX_WIDTH } from 'ui/shared/layout/utils';
import NetworkAddToWallet from 'ui/shared/NetworkAddToWallet';

import FooterLinkItem from './FooterLinkItem';
import IntTxsIndexingStatus from './IntTxsIndexingStatus';
import getApiVersionUrl from './utils/getApiVersionUrl';

const MAX_LINKS_COLUMNS = 4;

const FRONT_VERSION_URL = `https://github.com/blockscout/frontend/tree/${config.UI.footer.frontendVersion}`;
const FRONT_COMMIT_URL = `https://github.com/blockscout/frontend/commit/${config.UI.footer.frontendCommit}`;

const Footer = () => {
  const { data: backendVersionData } = useApiQuery('config_backend_version', {
    queryOptions: {
      staleTime: Infinity,
    },
  });
  const apiVersionUrl = getApiVersionUrl(backendVersionData?.backend_version);
  const issueUrl = useIssueUrl(backendVersionData?.backend_version);
  const logoColor = useColorModeValue('blue.600', 'white');

  const BLOCKSCOUT_LINKS = [
    {
      icon: 'social/telega' as const,
      iconSize: '20px',
      text: 'Telegram',
      url: 'https://t.me/OhoOfficial',
    },
    {
      icon: 'ohonews' as const,
      iconSize: '24px',
      text: 'News Channel',
      url: 'https://t.me/ohonews',
    },
    {
      icon: 'social/twitter' as const,
      iconSize: '18px',
      text: 'X/Twitter',
      url: 'https://x.com/OhoBlockchain',
    },
    {
      icon: 'social/discord' as const,
      iconSize: '24px',
      text: 'Discord',
      url: 'https://discord.gg/tfShbvkGzd',
    },
    {
      icon: 'email' as const,
      iconSize: '20px',
      text: 'Contact Us',
      url: 'https://oho.ai/contact-us',
    },
    {
      icon: 'globe' as const,
      iconSize: '22px',
      text: 'About Oho',
      url: 'https://www.oho.ai/ohodashboard',
    },
    {
      icon: 'docs' as const,
      iconSize: '20px',
      text: 'Oho Documentation',
      url: 'https://developer.oho.ai',
    },
    {
      icon: 'block' as const,
      iconSize: '20px',
      text: 'Oho Mainnet',
      url: 'https://ohoscan.com',
    },
  ];

  const fetch = useFetch();

  const { isPlaceholderData, data: linksData } = useQuery<
    unknown,
    ResourceError<unknown>,
    Array<CustomLinksGroup>
  >({
    queryKey: ['footer-links'],
    queryFn: async () =>
      fetch(config.UI.footer.links || '', undefined, {
        resource: 'footer-links',
      }),
    enabled: Boolean(config.UI.footer.links),
    staleTime: Infinity,
    placeholderData: [],
  });

  const colNum = isPlaceholderData
    ? 1
    : Math.min(linksData?.length || Infinity, MAX_LINKS_COLUMNS) + 1;

  const renderNetworkInfo = React.useCallback(
    (gridArea?: GridProps['gridArea']) => {
      return (
        <Flex
          gridArea={gridArea}
          flexWrap='wrap'
          columnGap={8}
          rowGap={6}
          mb={{ base: 5, lg: 4 }}
          _empty={{ display: 'none' }}
        >
          {!config.UI.indexingAlert.intTxs.isHidden && <IntTxsIndexingStatus />}
          <NetworkAddToWallet />
        </Flex>
      );
    },
    []
  );

  const renderProjectInfo = React.useCallback(
    (gridArea?: GridProps['gridArea']) => {
      return (
        <Box gridArea={gridArea}>
          <Link fontSize='24px' mt='0px' href='https://www.oho.ai/dashboard'>
            oho.ai
          </Link>
          <Text mt='0px' fontSize='s' color='#b065a9'>
            The Universal Layer 1-for-All blockchain powered by $OHO Coin.
          </Text>
          <VStack spacing={1} mt='0px' alignItems='start'>
            {apiVersionUrl && (
              <Text fontSize='xs' color='#7daa6a'>
                Oho Blockchain. All Rights Reserved.
                {/* Backend:{" "}
                <Link href={apiVersionUrl} target="_blank">
                  {backendVersionData?.backend_version}
                </Link> */}
              </Text>
            )}
            {/* {frontendLink && (
              <Text fontSize="xs">Frontend: {frontendLink}</Text>
            )} */}
          </VStack>
        </Box>
      );
    },

    [apiVersionUrl, backendVersionData?.backend_version, logoColor]
  );

  const containerProps: HTMLChakraProps<'div'> = {
    as: 'footer',
    borderTopWidth: '1px',
    borderTopColor: 'solid',
  };

  const contentProps: GridProps = {
    px: {
      base: 4,
      lg: config.UI.navigation.layout === 'horizontal' ? 6 : 12,
      '2xl': 6,
    },
    py: { base: 4, lg: 8 },
    gridTemplateColumns: { base: '1fr', lg: 'minmax(auto, 470px) 1fr' },
    columnGap: { lg: '32px', xl: '100px' },
    maxW: `${CONTENT_MAX_WIDTH}px`,
    m: '0 auto',
  };

  const renderRecaptcha = (gridArea?: GridProps['gridArea']) => {
    if (!config.services.reCaptchaV2.siteKey) {
      return <Box gridArea={gridArea} />;
    }

    return (
      <Box gridArea={gridArea} fontSize='xs' lineHeight={5} mt={6} color='text'>
        <span>This site is protected by reCAPTCHA and the Google </span>
        <Link href='https://policies.google.com/privacy' isExternal>
          Privacy Policy
        </Link>
        <span> and </span>
        <Link href='https://policies.google.com/terms' isExternal>
          Terms of Service
        </Link>
        <span> apply.</span>
      </Box>
    );
  };

  if (config.UI.footer.links) {
    return (
      <Box {...containerProps}>
        <Grid {...contentProps}>
          <div>
            {renderNetworkInfo()}
            {renderProjectInfo()}
            {renderRecaptcha()}
          </div>

          <Grid
            gap={{
              base: 6,
              lg: colNum === MAX_LINKS_COLUMNS + 1 ? 2 : 8,
              xl: 12,
            }}
            gridTemplateColumns={{
              base: 'repeat(auto-fill, 160px)',
              lg: `repeat(${colNum}, 135px)`,
              xl: `repeat(${colNum}, 160px)`,
            }}
            justifyContent={{ lg: 'flex-end' }}
            mt={{ base: 8, lg: 0 }}
          >
            {[
              { title: 'Blockscout', links: BLOCKSCOUT_LINKS },
              ...(linksData || []),
            ]
              .slice(0, colNum)
              .map((linkGroup) => (
                <Box key={linkGroup.title}>
                  <Skeleton
                    fontWeight={500}
                    mb={3}
                    display='inline-block'
                    isLoaded={!isPlaceholderData}
                  >
                    {linkGroup.title}
                  </Skeleton>
                  <VStack spacing={1} alignItems='start'>
                    {linkGroup.links.map((link) => (
                      <FooterLinkItem
                        {...link}
                        key={link.text}
                        isLoading={isPlaceholderData}
                      />
                    ))}
                  </VStack>
                </Box>
              ))}
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Box {...containerProps}>
      <Grid
        {...contentProps}
        gridTemplateAreas={{
          lg: `
          "network links-top"
          "info links-bottom"
          "recaptcha links-bottom"
        `,
        }}
      >
        {renderNetworkInfo({ lg: 'network' })}
        {renderProjectInfo({ lg: 'info' })}
        {renderRecaptcha({ lg: 'recaptcha' })}

        <Grid
          gridArea={{ lg: 'links-bottom' }}
          gap={1}
          gridTemplateColumns={{
            base: 'repeat(auto-fill, 160px)',
            lg: 'repeat(2, 160px)',
            xl: 'repeat(3, 160px)',
          }}
          gridTemplateRows={{
            base: 'auto',
            lg: 'repeat(3, auto)',
            xl: 'repeat(2, auto)',
          }}
          gridAutoFlow={{ base: 'row', lg: 'column' }}
          alignContent='start'
          justifyContent={{ lg: 'flex-end' }}
          mt={{ base: 8, lg: 0 }}
        >
          {BLOCKSCOUT_LINKS.map((link) => (
            <FooterLinkItem {...link} key={link.text} />
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default React.memo(Footer);
